img { 
  -khtml-user-drag: none; 
  -moz-user-drag: none; 
  -o-user-drag: none; 
  pointer-events: none;
  user-select: none;
}


/*
  Burger menu
*/

#Burger-menu-button {
  z-index: 10;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 20px;
  padding: 25px;
  border: 1px;
  border-radius: 20px;
}

@media (hover: hover) {
  #Burger-menu-button:hover, .Burger-page-button:hover {
    background-color: #ff7188;
    cursor: pointer;
  }

  .Card-button:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  #Contact-button:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}


#Burger-icon {
  position: sticky;
}

#Cross-icon {
  position: sticky;
  display: none;
}

#Burger-column {
  z-index: 9;
  flex: 1;
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: -20px;
  width: 350px;
  height: 100vh;
  border: 1px;
  border-radius: 20px;
  background-color: #FF5470;
}

.Burger-page-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px;
  border-radius: 20px;
}

.Burger-button-text {
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  color: #FCF7F8;
}

/*
  Page structure
*/

.Page-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.Page-container, #Right-wave, #Left-wave {
  height: 1200px;
}



/*
  Header structure
*/

.Header-section {
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.Header-icon {
  position: relative;
  margin-right: 10px;
}

.Icon-name {
  font-size: 45px;
  font-weight: 400;
  font-style: normal;
  color: #333333;
}

/*
  Home page
*/

#Blob-bg {
  z-index: 2;
  position: absolute;
  scale: 130%;
  transform: translate(-49%,-5%);
}

#Home-page, #About-page, #Project-page, #Contact-page {
  background: #FCF7F8;
}

#Home-section {
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#Home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#Home-space {
  width: 80px;
}

.Home-heading {
  font-size: 100px;
  font-weight: 700;
  font-style: bold;
  color: #333333;
  line-height: 100px;
}

.Home-subheading {
  font-size: 35px;
  font-weight: 400;
  font-style: normal;
  color: #525252;
}

/*
  About Page
*/

#about-wave-bg {
  z-index: 2;
  position: absolute;
  scale: 110%;
}

#About-section {
  z-index: 3;
}

#About-space{
  height: 60px;
}

.About-heading {
  line-height: 120px;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
}

.About-text {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
}

/*
  Projects Page
*/

#wave-diagonal-bg {
  z-index: 2;
  position: absolute;
  scale: 120%;
  transform: translate(-3px,0%);
}

#Project-section {
  z-index: 3;
  width: 950px;

}

.Project-heading {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
}

#Project-space {
  height: 70px;
}

.Card {
  position: relative;
  background-color: #333333;
  width: 450px;
  height: 650px;
  border: 10px;
  border-radius: 45px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Card-image {
  position: relative;
  width: 93%;
}

.Card-heading {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  color: #FCF7F8;
  padding: 20px;
}

.Card-content {
  height: 25%;
  width: 80%;
}

.Card-text, .Card-year {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #FCF7F8;
  text-align: left;
}

.Card-year {
  padding-right: 30px;
  align-self: flex-end;
}

#Card-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Card-button {
  display: flex;
}


#Card-right-button {
  float: right;
}

#Card-left-button {
  float: left;
}

#Coming-soon-card {
  display: none;
}

/*
  Contact Page
*/
#Contact-circle-section {
  position: absolute;
  scale: 100%;
}

#Contact-section {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
}

.Contact-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  margin-bottom: 50px;
}

#Contact-button {
  width: 250px;
  height: 60px;
  background-color: #333333;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #FCF7F8;
  text-decoration: none;
  border: 1px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

